import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormGroup, Label } from 'reactstrap';
import AuthInput from '../../../Custom/AuthInput';
import { useHistory, useLocation } from 'react-router-dom';
import { GetDomainDetails, UserWidgetLoginAPI } from '../../../services/Auth';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import toast, { Toaster } from 'react-hot-toast';
import { useEmbeddedWallet } from '@thirdweb-dev/react';
import { createParamsData } from '../../../services/params';
import LoginPageLoader from '../../SkeltonLoaders/LoginPageLoader';
import CryptoJS from 'crypto-js';

export function decryptText(text) {
  if (!process.env.REACT_APP_ENCRYPTION_KEY || !process.env.REACT_APP_IV_KEY) {
    console.error('Encryption/IV keys are not set');
    return '';
  }

  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);

  if (text === null || typeof text === 'undefined') return text;

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(text) },
    key,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encryptText(text) {
  if (!process.env.REACT_APP_ENCRYPTION_KEY || !process.env.REACT_APP_IV_KEY) {
    console.error('Encryption key or IV key is not set.');
    return null;
  }

  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);

  try {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.error('Encryption failed:', error);
    return null;
  }
}
export function getParamValue(label, newParams, paramValues) {
  if (newParams[label]) {
    return newParams[label];
  } else if (paramValues[label]) {
    return paramValues[label];
  }
  return null;
}

export function getDomainName(hostname) {
  let parts = hostname.split('.');
  if (parts.length >= 2) {
    return parts.slice(-2).join('.');
  } else {
    return hostname;
  }
}

function Login({ isWidget }) {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  const [domainDetail, setDomainDetail] = useState(null);
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  const { sendVerificationEmail } = useEmbeddedWallet();

  const handleSubmit = async (values) => {
    let data = {
      email: values.email,
      mode: getParamValue('mode', newParams, paramsValue)
    };
    setIsLoading(true);

    if (getParamValue('mode', newParams, paramsValue) === 'web2') {
      UserWidgetLoginAPI({ ...data, otp_verification: true })
        .then((res) => {
          localStorage.setItem('email', values.email);
          if (res?.data?.data?.data?.payload?.accessToken) {
            localStorage.setItem(
              'authAccessToken',
              res?.data?.data?.data?.payload?.accessToken
            );
          }

          setIsLoading(false);
          history.push({
            pathname: `${isWidget ? '/buy-sell/phygital' : '/phygital'}`,
            search: `${location.search}`,
            state: { email: values.email }
          });
        })
        .catch((err) => {
          const errorMessage = err?.data?.error?.message;
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      await sendVerificationEmail({ email: values.email })
        .then((res) => {
          UserWidgetLoginAPI(data)
            .then((res) => {
              localStorage.setItem('email', values.email);
              setIsLoading(false);
              history.push({
                pathname: `${isWidget ? '/buy-sell/verification' : '/verification'}`,
                search: `${location.search}`,
                state: { email: values.email }
              });
            })
            .catch((err) => {
              const errorMessage = err?.data?.error?.message;
              toast.error(notificationMessageFunction(errorMessage), {
                style: { fontSize: '12px' }
              });
            });
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(notificationMessageFunction(err.message), {
            style: { fontSize: '12px' }
          });
        });
    }
  };

  const getDomainDetailFunc = () => {
    setIsLoading(true);

    GetDomainDetails({ domain: getDomainName(window.location.hostname) })
      .then((res) => {
        setIsLoading(false);

        setDomainDetail(res.data.data.data);
      })
      .catch((err) => {});
    setIsLoading(false);
  };

  useEffect(() => {
    getDomainDetailFunc();
  }, []);

  useEffect(() => {
    if (
      getParamValue('mode', newParams, paramsValue) === 'fast' &&
      getParamValue('email', newParams, paramsValue) !== ''
    ) {
      fastModeLogin();
    }
  }, [email, newParams, paramsValue]);

  const fastModeLogin = () => {
    let data = { email: email, mode: getParamValue('mode', newParams, paramsValue) };
    UserWidgetLoginAPI(data)
      .then((res) => {
        localStorage.setItem('email', email);
        localStorage.setItem(
          'authAccessToken',
          res?.data?.data?.data?.payload?.accessToken
        );
        setIsLoading(false);
        history.push({
          pathname: `${isWidget ? '/buy-sell/buy-nft' : '/buy-nft'}`,
          search: `${location.search}`,
          state: { email: email }
        });
      })
      .catch((err) => {
        console.log({ err });

        const errorMessage = err?.data?.error?.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  useEffect(() => {
    const paramsDetail = window.location.search.split('&');
    const emailParam = paramsDetail.find((param) => param.includes('email'));
    if (emailParam) {
      setEmail(getParamValue('email', newParams, paramsValue));
    }
  }, [newParams, paramsValue]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="p-3" style={{ width: '100%' }}>
      {isLoading ? (
        <LoginPageLoader />
      ) : (
        <>
          {getParamValue('mode', newParams, paramsValue) === 'fast' && email ? (
            <>{fastModeLogin()}</>
          ) : (
            <div>
              <span className="text-left font-size-32 font-weight-bold text-black ">
                Welcome to{' '}
                {domainDetail && domainDetail.organization_name
                  ? domainDetail.organization_name
                  : ''}
              </span>
              <div className="mt-3">
                <Formik
                  initialValues={{
                    email: email ? email : ''
                  }}
                  enableReinitialize
                  validationSchema={SignupSchema}
                  onSubmit={handleSubmit}>
                  {({ values, errors, touched, handleChange }) => (
                    <Form>
                      <div className="mb-3">
                        <FormGroup>
                          <Label htmlFor="email" className="text-black font-size-12">
                            Email Address
                          </Label>
                          <AuthInput
                            type="text"
                            values={values}
                            placeholder="Enter email address"
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            name="email"
                            className={`font-size-12 input-placeholder`}
                          />
                        </FormGroup>
                      </div>

                      <div className="">
                        <button
                          id="login-button"
                          style={{ width: '100px' }}
                          type="submit"
                          className="btn btn-auth text-capitalize"
                          onSubmit={handleSubmit}>
                          <div className="text-white font-size-12 my-1">Email Auth</div>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </>
      )}

      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default Login;
