import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import {
  ConnectWallet,
  metamaskWallet,
  rainbowWallet,
  ThirdwebProvider,
  useAddress,
  useConnectedWallet,
  useConnectionStatus,
  useSetConnectionStatus,
  useSigner,
  useWallet
} from '@thirdweb-dev/react';
import {
  GetMyProfileAPI,
  GetOrderDetailsAPI,
  MultipleProductDetailsFetch,
  ProductDetailsFetch,
  TriggerWertWebhook,
  UserWidgetLoginEmailCheckAPI
} from '../../../services/Auth';
import { createParamsData } from '../../../services/params';
import { useHistory, useLocation } from 'react-router-dom';
import './CryptoPayment.css';
import { clickIdFunc, commodityAmount, nameFunc, redirectOrder } from './BuyNFT';
import uuid4 from 'uuid4';
import VerificationLoader from '../../SkeltonLoaders/VerificationLoader';
import { decryptText, encryptText, getParamValue } from '../Auth/Login';

const CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_customer',
        type: 'address'
      },
      {
        internalType: 'string',
        name: '_product_id',
        type: 'string'
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'purchase',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_customer',
        type: 'address'
      },
      {
        internalType: 'string[]',
        name: '_product_ids',
        type: 'string[]'
      },
      {
        internalType: 'uint256[]',
        name: '_quantities',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'bulkPurchase',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'customer', type: 'address' },
      { indexed: true, name: 'product_id', type: 'string' },
      { indexed: false, name: 'quantity', type: 'uint256' },
      { indexed: false, name: 'amount', type: 'uint256' }
    ],
    name: 'PurchaseMade',
    type: 'event'
  }
];

function CryptoPayment({ isWidget }) {
  const parentURL = document.referrer;
  let parentHostname = null;

  if (parentURL) {
    parentHostname = new URL(parentURL).hostname;
  }

  const history = useHistory();
  const connected = useConnectedWallet();
  const address = useAddress();
  const signer = useSigner();
  const wallet = useWallet();
  const setConnectionStatus = useSetConnectionStatus();
  const connectionStatus = useConnectionStatus();

  const [contractInstance, setContractInstance] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTxLoading, setIsTxLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const [scAddress, setScAddress] = useState(null);
  const [signerData, setSignerData] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const ordersFunction = async (order_id) => {
    const response = await GetOrderDetailsAPI(order_id);

    return response;
  };

  const location = useLocation();
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  useEffect(() => {
    if (connected === undefined) {
      setConnectionStatus('disconnected');
    }
  }, [connected, setConnectionStatus]);

  const emailLoginCheck = () => {
    UserWidgetLoginEmailCheckAPI({ email: localStorage.getItem('email') })
      .then(async (res) => {
        if (res?.data?.data?.accessToken) {
          localStorage.setItem('authAccessToken', res?.data?.data?.accessToken);
        }
      })
      .catch((err) => {});
  };

  const myProfileFunction = () => {
    if (localStorage.getItem('authAccessToken')) {
      GetMyProfileAPI()
        .then((res) => {
          setUserData(res.data.data.data);
        })
        .catch((err) => {
          history.push({
            pathname: `${isWidget ? '/buy-sell/login' : '/login'}`,
            search: `${location.search}`
          });
        });
    }
  };

  useEffect(() => {
    myProfileFunction();
    emailLoginCheck();
  }, []);

  const productDetails = () => {
    setIsLoading(true);
    setErrorMessage('');

    ProductDetailsFetch(
      getParamValue('product_id', newParams, paramsValue),
      getParamValue('api_key', newParams, paramsValue),
      Number(
        getParamValue('quantity', newParams, paramsValue)
          ? getParamValue('quantity', newParams, paramsValue)
          : 1
      ),
      parentHostname,
      encryptText('one_time'),
      paramsValue['hash']
    )
      .then((res) => {
        setIsLoading(false);
        setProductDetail(res.data.data.data);
        setScAddress(res.data.data.sc_address);
      })
      .catch((err) => {
        const errorMessage = err?.data?.error?.message;
        setErrorMessage(errorMessage);
        setIsLoading(false);
      });
  };

  const multipleProductsDetails = () => {
    setIsLoading(true);
    setErrorMessage('');
    let productIds = getParamValue('product_ids', newParams, paramsValue).trim();
    let splitIds = productIds.endsWith(',')
      ? productIds.slice(0, -1).split(',')
      : productIds.split(',');
    splitIds = [...new Set(splitIds)];

    MultipleProductDetailsFetch(
      splitIds,
      getParamValue('api_key', newParams, paramsValue),
      getParamValue('quantities', newParams, paramsValue)
        ? [getParamValue('quantities', newParams, paramsValue)][0].split(',').map(Number)
        : ['1,1'][0].split(',').map(Number),
      parentHostname,
      encryptText('one_time'),
      paramsValue['hash']
    )
      .then((res) => {
        setIsLoading(false);
        setProductDetail(res.data.data.data);
        setScAddress(res.data.data.sc_address);
      })
      .catch((err) => {
        const errorMessage = err?.data?.error?.message;
        setErrorMessage(errorMessage);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (signerData && scAddress) {
      const contract = new ethers.Contract(scAddress, CONTRACT_ABI, signerData);
      setContractInstance(contract);
    } else {
      console.log('Address or signerData not available.');
    }
  }, [walletConnected, scAddress, signerData, addressData]);

  const callPurchase = async () => {
    const chain = await wallet.getChainId();
    if (contractInstance && signerData && chain === 137) {
      setIsTxLoading(true);
      let orderId = uuid4();
      const click_id = clickIdFunc(productDetail, userData, paramsValue, newParams);
      try {
        let tx;
        if (productDetail && productDetail.product_name) {
          const amountToSend = ethers.utils.parseEther(
            commodityAmount(productDetail, paramsValue, newParams).toString()
          );
          const gasLimit = await contractInstance.estimateGas.purchase(
            addressData,
            productDetail.product_id,
            Number(getParamValue('quantity', newParams, paramsValue) || 1),
            amountToSend
          );
          tx = await contractInstance.purchase(
            addressData,
            productDetail.product_id,
            Number(
              getParamValue('quantity', newParams, paramsValue)
                ? Number(getParamValue('quantity', newParams, paramsValue))
                : 1
            ),
            amountToSend,
            { gasLimit: gasLimit.add(300000) }
          );
        }

        if (productDetail && productDetail.length > 0) {
          let productIds = productDetail.map((product) => product.product_id);
          let quantities = [getParamValue('quantities', newParams, paramsValue)][0]
            .split(',')
            .map(Number);
          const amountToSend = ethers.utils.parseEther(
            commodityAmount(productDetail, paramsValue, newParams).toString()
          );
          const gasLimit = await contractInstance.estimateGas.purchase(
            addressData,
            productDetail.product_id,
            Number(getParamValue('quantity', newParams, paramsValue) || 1),
            amountToSend
          );
          tx = await contractInstance.bulkPurchase(
            addressData,
            productIds,
            quantities,
            amountToSend,
            { gasLimit: gasLimit.add(300000) }
          );
        }

        let data = {
          type: 'pending',
          click_id,
          order: {
            transaction_id: tx.hash,
            address: tx.from,
            id: orderId,
            quote_amount: commodityAmount(productDetail, paramsValue, newParams),
            quote: 'USDT',
            base: 'USDT',
            base_amount: commodityAmount(productDetail, paramsValue, newParams)
          }
        };
        TriggerWertWebhook(data);
        const receipt = await tx.wait();
        data = {
          type: 'order_complete',
          click_id,
          order: {
            transaction_id: receipt.transactionHash,
            address: tx.from,
            id: orderId,
            quote_amount: commodityAmount(productDetail, paramsValue, newParams),
            quote: 'USDT',
            base: 'USDT',
            base_amount: commodityAmount(productDetail, paramsValue, newParams)
          }
        };
        TriggerWertWebhook(data);
        redirectOrder(
          {
            status: 'order_complete',
            tx_id: receipt.transactionHash,
            order_id: orderId,
            return_path: isWidget ? '/buy-sell/crypto-payment' : '/crypto-payment'
          },
          isWidget,
          location,
          paramsValue,
          history,
          newParams,
          ordersFunction
        );
        setIsTxLoading(false);
      } catch (err) {
        if (err.code === 4100 || err.code === -32603) {
          setConnectionStatus('disconnected');
        } else if (err.code !== 'ACTION_REJECTED') {
          let data = {
            type: 'order_failed',
            click_id,
            order: {
              transaction_id: '',
              blockNumber: '',
              address: addressData,
              id: orderId,
              quote_amount: commodityAmount(productDetail, paramsValue, newParams),
              quote: 'USDT',
              base: 'USDT',
              base_amount: commodityAmount(productDetail, paramsValue, newParams)
            }
          };
          redirectOrder(
            {
              status: 'failed',
              order_id: orderId,
              return_path: isWidget ? '/buy-sell/crypto-payment' : '/crypto-payment'
            },
            isWidget,
            location,
            paramsValue,
            history,
            newParams,
            ordersFunction
          );
          TriggerWertWebhook(data);
          console.error('Error executing purchase transaction:', { err });
        }
        setIsTxLoading(false);
      }
    } else {
      console.log('Contract instance or signer not available.');
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem('authAccessToken') ||
      localStorage.getItem('authAccessToken') === undefined
    ) {
      history.push({
        pathname: `${isWidget ? '/buy-sell/login' : '/login'}`,
        search: `${location.search}`
      });
    } else {
      if (
        paramsValue['product_ids'] &&
        getParamValue('quantities', newParams, paramsValue)
      ) {
        multipleProductsDetails();
      } else {
        productDetails();
      }
    }
  }, []);

  useEffect(() => {
    if (address && signer) {
      console.log('Address:', addressData);
      console.log('Signer:', signerData);
      setSignerData(signer);
      setAddressData(address);
    } else {
    }
  }, [walletConnected, addressData, signerData, signer, address]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isTxLoading) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isTxLoading]);

  return (
    <div
      className="text-black crypto-container"
      style={{
        paddingLeft: '48px',
        paddingRight: '48px',
        paddingBottom: '48px',
        paddingTop: '24px',
        background: 'white',
        width: '100%',
        borderRadius: '24px'
      }}>
      {isLoading ? (
        <div style={{ borderRadius: '24px', width: '100%' }}>
          <VerificationLoader />
        </div>
      ) : (
        <>
          {productDetail &&
          (Object.keys(productDetail).length > 0 || productDetail.length > 0) &&
          userData &&
          Object.keys(userData).length > 0 &&
          errorMessage === '' ? (
            <ThirdwebProvider
              supportedWallets={[metamaskWallet(), rainbowWallet()]}
              clientId={process.env.REACT_APP_THIRD_WEB_CLIENT_ID}
              autoSwitch={true}
              autoConnect={true}
              activeChain={'polygon'}>
              <span className="font-size-24 font-weight-medium">
                Buy{' '}
                {productDetail && productDetail.length > 0
                  ? productDetail[0].category
                  : productDetail && productDetail.category
                  ? productDetail.category
                  : 'Wert Sample'}
              </span>
              <div className="product-container">
                <div className="product-image">
                  <img
                    alt="Star"
                    src="https://rampnalysisdocs.s3.ap-south-1.amazonaws.com/16044-1723070666976.png"
                  />
                </div>
                <div className="product-details">
                  <div className="author-info">
                    <img
                      className="avatar_image"
                      src={
                        productDetail && productDetail.length > 0
                          ? productDetail[0].author_image
                          : productDetail && productDetail.author_image
                          ? productDetail.author_image
                          : 'https://partner-sandbox.wert.io/sample_nft.png'
                      }
                      alt="Author"
                    />
                    <span className="font-weight-medium font-weight-16">
                      by{' '}
                      {productDetail && productDetail.length > 0
                        ? productDetail[0].author_name
                        : productDetail && productDetail.author_name
                        ? productDetail.author_name
                        : 'Wert'}
                    </span>
                  </div>
                  <div className="product-title">
                    <span className="font-size-24 font-weight-medium">
                      {nameFunc(productDetail, paramsValue, newParams).length > 25
                        ? nameFunc(productDetail, paramsValue, newParams).substring(
                            0,
                            25
                          ) + '...'
                        : nameFunc(productDetail, paramsValue, newParams)}
                    </span>
                  </div>
                  <div className="payment-info">
                    <span style={{ color: '#8e8eae' }}>You Pay</span>
                    <span className="font-size-24 font-weight-medium">
                      {commodityAmount(productDetail, paramsValue, newParams)} USDT
                    </span>
                  </div>
                  <div className="wallet-connection">
                    <span className="text-danger font-weight-medium font-size-12">
                      {isTxLoading
                        ? 'Please do not refresh or close this page as it may cause your order to fail.'
                        : ''}
                    </span>{' '}
                    <ConnectWallet
                      modalSize="compact"
                      modalTitle=""
                      btnTitle="Connect your wallet"
                      className="font-size-16 font-weight-medium"
                      hideTestnetFaucet={true}
                      showThirdwebBranding={false}
                      switchToActiveChain={true}
                      style={{
                        background: 'black',
                        color: 'white',
                        width: '100%',
                        height: '56px'
                      }}
                      onConnect={async (walletDetails) => {
                        const address = await walletDetails.getAddress();
                        const signData = await walletDetails.getSigner();
                        setAddressData(address);
                        setSignerData(signData);
                        console.log('Wallet connected successfully!', signData, address);
                        setWalletConnected(true);
                        setConnectionStatus('connected');
                      }}
                      hideBuyButton
                      hideReceiveButton
                      hideDisconnect
                      hideSendButton
                      dropdownPosition={{ align: 'center' }}
                    />{' '}
                    {contractInstance &&
                      connectionStatus === 'connected' &&
                      signerData &&
                      Object.keys(signerData).length > 0 && (
                        <button
                          onClick={() => {
                            if (!isTxLoading) {
                              callPurchase();
                            }
                          }}
                          className="btn btn-auth text-capitalize mt-2 font-size-16 font-weight-medium"
                          style={{ background: 'black', height: '56px' }}>
                          {isTxLoading ? (
                            <div className="spinner">
                              <i
                                className="ri-loader-line spin-icon"
                                style={{ fontSize: '30px' }}></i>
                            </div>
                          ) : (
                            'Purchase'
                          )}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </ThirdwebProvider>
          ) : (
            <span
              id="widget-page-error"
              className="p-3 font-size-24 font-weight-bold text-danger">
              {errorMessage}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default CryptoPayment;
