import React, { useRef, useState } from 'react';
import Tilt from 'react-parallax-tilt';
import './collectible.css';
import treasureImage from '../../../assets/images/treasure-chest.png';
import { useEffect } from 'react';
import moment from 'moment';

function CollectibleItem({ item }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const cardRef = useRef(null);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  function truncateText(text, maxLength) {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  }

  useEffect(() => {
    const card = cardRef.current;
    if (!card) return;

    const style = document.querySelector('.hover');

    const handleMouseMove = (e) => {
      const { offsetX: l, offsetY: t } = e;
      const { offsetHeight: h, offsetWidth: w } = card;

      const lp = Math.abs(Math.floor((100 / w) * l) - 100);
      const tp = Math.abs(Math.floor((100 / h) * t) - 100);
      const bg = `background-position: ${lp}% ${tp}%;`;
      const dynamicStyle = `.card.active:before { ${bg} }`;

      card.classList.add('active');
      style.textContent = dynamicStyle;
    };

    const handleMouseOut = () => {
      card.classList.remove('active');
    };

    card.addEventListener('mousemove', handleMouseMove);
    card.addEventListener('mouseout', handleMouseOut);

    return () => {
      card.removeEventListener('mousemove', handleMouseMove);
      card.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  return (
    <div className="card-animation">
      <Tilt style={{ width: '200px', margin: '10px', paddingLeft: '10px' }}>
        <main>
          <div
            ref={cardRef}
            className={`card ${isFlipped ? 'is-flipped' : ''}`}
            onClick={handleFlip}
            style={{ borderRadius: '8px', background: 'black' }}>
            {isFlipped ? (
              <div className="dcard">
                <div className="frame">
                  <div className="card-img">
                    <img
                      alt="Collectible Item"
                      className="p-2"
                      src={item.url}
                      style={{ height: '200px', width: '200px' }}
                    />
                  </div>
                  <div className="card-content">
                    <div className="flex-content font-size-12">
                      Item Value
                      <span className="">
                        {item.item_price} {item.fungible.toUpperCase()}
                      </span>
                    </div>
                    <div className="flex-content">
                      <span className="title font-size-16" style={{ color: '#a1a1aa' }}>
                        {item.item_name}
                      </span>
                      <div>
                        <span className="red-border font-size-10">
                          {item.item_rarity}
                        </span>
                      </div>
                    </div>
                    <ul className="text-xs">
                      <li className="font-size-10">
                        Released: {moment(item.created_at).format('DD MMM YYYY')}
                      </li>
                      <li className="font-size-10">
                        Description: {truncateText(item.item_description, 70)}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="dcard">
                <div className="frame">
                  <div className="card-img">
                    <div
                      className="d-flex align-items-center justify-content-center flex-column levitating-container"
                      style={{ width: '200px', minHeight: '330px' }}>
                      <span className="font-size-14 mb-4 font-weight-700">
                        Tap to Reveal
                      </span>
                      <img
                        src={treasureImage}
                        alt="Treasure"
                        className="levitating-reflection-image"
                        style={{ width: '70px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <style className="hover"></style>
          </div>
        </main>
      </Tilt>
    </div>
  );
}

export default CollectibleItem;
