import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function SuccessLoader() {
  return (
    <Stack spacing={1} className="p-3">
      <div className="d-flex flex-column align-items-center">
        <Skeleton variant="circular" width={60} height={60} />
        <Skeleton variant="text" width={'40%'} sx={{ fontSize: '2rem' }} />
      </div>
      <Skeleton variant="text" sx={{ fontSize: '0.2rem' }} />
      <div className="d-flex justify-content-between">
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
      </div>
      <div className="d-flex justify-content-between">
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
      </div>
      <div className="d-flex justify-content-between">
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
      </div>
      <div className="d-flex justify-content-between">
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
      </div>
      <div className="d-flex justify-content-between mb-3">
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
      </div>
      <div className="d-flex justify-content-center">
        <Skeleton variant="rounded" width={'40%'} height={60} className="mt-5" />
      </div>
    </Stack>
  );
}

export default SuccessLoader;
