import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Row } from 'reactstrap';
import { createParamsData } from '../../../services/params';
import { ProductDetailsFetch } from '../../../services/Auth';
import PageLoader from '../../../components/Common/CustomLoader/loader';
import SimpleBar from 'simplebar-react';
import { decryptText, getParamValue } from '../Auth/Login';

function Casino({ isWidget }) {
  const [currentBalance, setCurrentBalance] = useState(30);
  const location = useLocation();
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  const [multiplier, setMultiplier] = useState(1);
  const [productDetail, setProductDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const productDetails = () => {
    setIsLoading(true);

    ProductDetailsFetch(
      getParamValue('product_id', newParams, paramsValue),
      getParamValue('api_key', newParams, paramsValue)
    )
      .then((res) => {
        setIsLoading(false);
        setProductDetail(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    productDetails();
  }, []);

  const handleClick = () => {
    history.push({
      pathname: `${isWidget ? '/buy-sell/buy-nft' : '/buy-nft'}`,
      state: { email: localStorage.getItem('email'), multiplier },
      search: `${location.search}`
    });
    setTimeout(() => {
      setCurrentBalance(currentBalance + productDetail.commodity_amount * multiplier);
    }, 10000);
  };

  return (
    <div className="p-3" style={{ width: '100%' }}>
      <div className="d-flex justify-content-between">
        <span className="text-left font-size-32 font-weight-bold text-black ">
          Charge your casino
        </span>
        <span className="text-black font-weight-bold mt-2 mr-3">${currentBalance}</span>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {productDetail &&
            (Object.keys(productDetail).length > 0 || productDetail.length > 0) && (
              <>
                <SimpleBar style={{ height: '542px', overflowX: 'hidden' }}>
                  <div className="mt-2">
                    <Row>
                      <Col lg={6} md={6}>
                        <div>
                          <img
                            src={productDetail.product_image}
                            alt=""
                            style={{
                              borderRadius: '10px',
                              border: '1px solid',
                              maxHeight: '450px',
                              maxWidth: '100%',
                              minWidth: '100px',
                              minHeight: '100px',
                              width: '100%',
                              objectFit: 'contain'
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-3 mt-2">
                          <Button
                            style={{ background: 'black', height: '50px', width: '50px' }}
                            onClick={() => {
                              if (multiplier > 1) {
                                setMultiplier(multiplier - 1);
                              }
                            }}
                            disabled={multiplier === 1}>
                            <i className="mdi mdi-minus text-black font-size-24 cursor-pointer text-white" />
                          </Button>
                          <span className="text-black font-size-20 mx-3 font-weight-bold">
                            {multiplier}
                          </span>
                          <Button
                            style={{ background: 'black', height: '50px', width: '50px' }}
                            onClick={() => {
                              if (multiplier < 25) {
                                setMultiplier(multiplier + 1);
                              }
                            }}
                            disabled={multiplier === 25}>
                            <i className="mdi mdi-plus text-black font-size-24 cursor-pointer text-white" />
                          </Button>
                          <span className="text-black font-size-16 ml-4 font-weight-bold">
                            X {productDetail.product_name}
                          </span>
                        </div>
                      </Col>
                      <Col lg={6} md={6} className="d-flex flex-column">
                        <div className="d-flex">
                          <img
                            src={productDetail.author_image}
                            alt=""
                            style={{
                              borderRadius: '50%',
                              border: '1px solid',
                              maxHeight: '70px',
                              minWidth: '70px',
                              minHeight: '70px',
                              objectFit: 'contain'
                            }}
                          />
                          <div className="d-flex flex-column ml-3 mt-2">
                            <span className="text-black font-weight-bold font-size-16">
                              {productDetail.author_name}
                            </span>
                            <span className="text-black font-weight-bold">
                              {productDetail.category}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column ml-2 mt-2">
                          <span className="text-black font-weight-bold font-size-20">
                            {productDetail.product_name}
                          </span>
                          <span className="text-black font-weight-bold font-size-18">
                            ${productDetail.commodity_amount}
                          </span>
                        </div>
                        <hr style={{ border: '1px solid grey', width: '100%' }} />
                        <div className="d-flex flex-column ml-2 mt-2">
                          <span className="text-black font-weight-medium font-size-18">
                            Order Amount: ${productDetail.commodity_amount} x {multiplier}
                          </span>
                          <span className="text-black font-weight-medium font-size-16">
                            = ${productDetail.commodity_amount * multiplier}
                          </span>
                        </div>
                        <div className="d-flex flex-column ml-2 mt-2">
                          <Button style={{ background: 'black' }} onClick={handleClick}>
                            Checkout
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </SimpleBar>
              </>
            )}
        </>
      )}
    </div>
  );
}

export default Casino;
