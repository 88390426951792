import React, { useCallback, useEffect, useState } from 'react';
import {
  GetMyProfileAPI,
  ProductDetailsFetch,
  ProductExchangeDetailsFetch,
  TriggerWertWebhook,
  // TriggerWertWebhook,
  UserWidgetLoginEmailCheckAPI
} from '../../../services/Auth';
import { createParamsData } from '../../../services/params';
import { useHistory, useLocation } from 'react-router-dom';
import './CryptoPayment.css';
import { clickIdFunc, commodityAmount, nameFunc } from './BuyNFT';
import VerificationLoader from '../../SkeltonLoaders/VerificationLoader';
import { AarcFundKitModal } from '@aarc-xyz/fundkit-web-sdk';
import uuid4 from 'uuid4';
import { decryptText, encryptText, getParamValue } from '../Auth/Login';

function CryptoExchange({ isWidget }) {
  const history = useHistory();
  const parentURL = document.referrer;
  let parentHostname = null;

  if (parentURL) {
    parentHostname = new URL(parentURL).hostname;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState(null);

  const location = useLocation();
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  const config = {
    appName: '1buy.io',
    appearance: {
      appName: '1buy.io',
      logoUrl:
        'https://static-assets.coinbase.com/ui-infra/illustration/v1/heroSquare/svg/light/desktopAuthorized-2.svg',
      themeColor: '#2D2D2D',
      textColor: '#2D2D2D',
      backgroundColor: '#FFF',
      highlightColor: '#F0F0F0',
      borderColor: '#2D2D2D',
      dark: {
        themeColor: '#FFF',
        textColor: '#FFF',
        backgroundColor: '#2D2D2D',
        highlightColor: '#2D2D2D',
        borderColor: '#2D2D2D'
      },
      roundness: 24,
      theme: 'light'
    },
    module: {
      exchange: { enabled: true, env: 'staging' },
      onRamp: {
        enabled: false,
        onRampConfig: {
          mode: 'deposit',
          customerId: '323232323',
          exchangeScreenTitle: 'Deposit funds in your wallet',
          networks: ['ethereum', 'polygon'],
          defaultNetwork: 'polygon'
        }
      },
      bridgeAndSwap: {
        enabled: false,
        fetchOnlyDestinationBalance: false,
        routeType: 'Value'
      }
    },
    destination: { walletAddress: destinationAddress },
    apiKeys: {
      aarcSDK: process.env.REACT_APP_AARC_API_KEY
    },
    events: {
      onTransactionSuccess: (data) => {
        console.log('onTransactionSuccess', data);
        data = {
          type: data.status,
          click_id: clickIdFunc(productDetail, userData, paramsValue, newParams),
          order: {
            transaction_id: data.txHash,
            address: '',
            id: uuid4(),
            quote_amount: commodityAmount(productDetail, paramsValue, newParams),
            quote: 'USDT',
            base: 'USDT',
            base_amount: commodityAmount(productDetail, paramsValue, newParams),
            payment_type: 'exchange'
          }
        };
        TriggerWertWebhook(data);
      },
      onTransactionError: (data) => {
        console.log('onTransactionError', data);
        data = {
          type: data.status,
          click_id: clickIdFunc(productDetail, userData, paramsValue, newParams),
          order: {
            fail_reason: data.message,
            transaction_id: '',
            address: '',
            id: uuid4(),
            quote_amount: commodityAmount(productDetail, paramsValue, newParams),
            quote: 'USDT',
            base: 'USDT',
            base_amount: commodityAmount(productDetail, paramsValue, newParams),
            payment_type: 'exchange'
          }
        };
      },
      onWidgetClose: () => {
        console.log('onWidgetClose');
      },
      onWidgetOpen: () => {
        console.log('onWidgetOpen');
      }
    },

    origin: window.location.origin
  };

  const AarcModal = new AarcFundKitModal(config);

  const emailLoginCheck = () => {
    UserWidgetLoginEmailCheckAPI({ email: localStorage.getItem('email') })
      .then(async (res) => {
        if (res?.data?.data?.accessToken) {
          localStorage.setItem('authAccessToken', res?.data?.data?.accessToken);
        }
      })
      .catch((err) => {});
  };

  const myProfileFunction = () => {
    if (localStorage.getItem('authAccessToken')) {
      GetMyProfileAPI()
        .then((res) => {
          setUserData(res.data.data.data);
        })
        .catch((err) => {
          history.push({
            pathname: `${isWidget ? '/buy-sell/login' : '/login'}`,
            search: `${location.search}`
          });
        });
    }
  };

  useEffect(() => {
    myProfileFunction();
    emailLoginCheck();
  }, []);

  const productDetails = () => {
    setIsLoading(true);
    setErrorMessage('');

    ProductDetailsFetch(
      getParamValue('product_id', newParams, paramsValue),
      getParamValue('api_key', newParams, paramsValue),
      Number(
        getParamValue('quantity', newParams, paramsValue)
          ? getParamValue('quantity', newParams, paramsValue)
          : 1
      ),
      parentHostname,
      encryptText('one_time'),
      paramsValue['hash']
    )
      .then((res) => {
        setIsLoading(false);
        setProductDetail(res.data.data.data);
      })
      .catch((err) => {
        const errorMessage = err?.data?.error?.message;
        setErrorMessage(errorMessage);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      !localStorage.getItem('authAccessToken') ||
      localStorage.getItem('authAccessToken') === undefined
    ) {
      history.push({
        pathname: `${isWidget ? '/buy-sell/login' : '/login'}`,
        search: `${location.search}`
      });
    } else {
      productDetails();
    }
  }, []);

  const productExchangeDetailsFunc = (data) => {
    setIsLoading(true);
    ProductExchangeDetailsFetch(data)
      .then((res) => {
        setDestinationAddress(res.data.data.data.wallet_address);
        AarcModal.init();

        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = err?.data?.error?.message;
        setErrorMessage(errorMessage);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userData) {
      productExchangeDetailsFunc({
        product_id: getParamValue('product_id', newParams, paramsValue),
        api_key: getParamValue('api_key', newParams, paramsValue),
        user_id: userData.user_id
      });
    }
  }, [userData]);

  const handleSubmit = useCallback(() => {
    if (productDetail) {
      AarcModal.updateDestinationChainId(137);
      AarcModal.updateDestinationToken(
        '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        137,
        Number(productDetail.commodity_amount) *
          Number(
            getParamValue('quantity', newParams, paramsValue)
              ? getParamValue('quantity', newParams, paramsValue)
              : 1
          )
      );
      AarcModal.updateDestinationContract(destinationAddress);
      AarcModal.openModal();
    }
  }, [AarcModal, productDetail, destinationAddress]);

  return (
    <div
      className="text-black crypto-container"
      style={{
        paddingLeft: '48px',
        paddingRight: '48px',
        paddingBottom: '48px',
        paddingTop: '24px',
        background: 'white',
        width: '100%',
        borderRadius: '24px'
      }}>
      {isLoading ? (
        <div style={{ borderRadius: '24px', width: '100%' }}>
          <VerificationLoader />
        </div>
      ) : (
        <>
          {productDetail &&
          (Object.keys(productDetail).length > 0 || productDetail.length > 0) &&
          userData &&
          Object.keys(userData).length > 0 &&
          errorMessage === '' ? (
            <>
              <span className="font-size-24 font-weight-medium">
                Buy{' '}
                {productDetail && productDetail.length > 0
                  ? productDetail[0].category
                  : productDetail && productDetail.category
                  ? productDetail.category
                  : 'Wert Sample'}
              </span>
              <div className="product-container">
                <div className="product-image">
                  <img
                    alt="Star"
                    src="https://rampnalysisdocs.s3.ap-south-1.amazonaws.com/16044-1723070666976.png"
                  />
                </div>
                <div className="product-details">
                  <div className="author-info">
                    <img
                      className="avatar_image"
                      src={
                        productDetail && productDetail.length > 0
                          ? productDetail[0].author_image
                          : productDetail && productDetail.author_image
                          ? productDetail.author_image
                          : 'https://partner-sandbox.wert.io/sample_nft.png'
                      }
                      alt="Author"
                    />
                    <span className="font-weight-medium font-weight-16">
                      by{' '}
                      {productDetail && productDetail.length > 0
                        ? productDetail[0].author_name
                        : productDetail && productDetail.author_name
                        ? productDetail.author_name
                        : 'Wert'}
                    </span>
                  </div>
                  <div className="product-title">
                    <span className="font-size-24 font-weight-medium">
                      {nameFunc(productDetail, paramsValue, newParams).length > 25
                        ? nameFunc(productDetail, paramsValue, newParams).substring(
                            0,
                            25
                          ) + '...'
                        : nameFunc(productDetail, paramsValue, newParams)}
                    </span>
                  </div>
                  <div className="payment-info">
                    <span style={{ color: '#8e8eae' }}>You Pay</span>
                    <span className="font-size-24 font-weight-medium">
                      {commodityAmount(productDetail, paramsValue, newParams)} USDT
                    </span>
                    {productDetail &&
                    Number(productDetail.commodity_amount) *
                      Number(
                        getParamValue('quantity', newParams, paramsValue)
                          ? getParamValue('quantity', newParams, paramsValue)
                          : 1
                      ) <
                      10 ? (
                      <span class="font-size-12 text-danger font-weight-medium">
                        The amount must be at least $10.
                      </span>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="wallet-connection">
                    <button
                      onClick={() => {
                        AarcModal?.updateDestinationContract(undefined);
                        handleSubmit();
                      }}
                      className="btn btn-auth text-capitalize mt-5 font-size-16 font-weight-medium"
                      style={{ background: 'black', height: '56px' }}
                      disabled={
                        productDetail &&
                        Number(productDetail.commodity_amount) *
                          Number(
                            getParamValue('quantity', newParams, paramsValue)
                              ? getParamValue('quantity', newParams, paramsValue)
                              : 1
                          ) >=
                          10
                          ? false
                          : true
                      }>
                      Purchase
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span
              id="widget-page-error"
              className="p-3 font-size-24 font-weight-bold text-danger">
              {errorMessage}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default CryptoExchange;
