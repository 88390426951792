import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function LoginPageLoader() {
  return (
    <Stack spacing={1} className="p-3">
      <Skeleton variant="rounded" width={40} height={40} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rounded" width={180} height={40} className="mt-4" />
    </Stack>
  );
}

export default LoginPageLoader;
