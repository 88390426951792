import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function VerificationLoader() {
  return (
    <Stack spacing={1} className="p-3">
      <div className="d-flex justify-content-between">
        <Skeleton variant="rounded" width={100} height={40} />
        <Skeleton variant="rounded" width={20} height={20} />
      </div>
      <div className="d-flex justify-content-between">
        <Skeleton
          variant="rounded"
          width={'49%'}
          height={'100px'}
          className="mt-4 me-2"
        />
        <Skeleton
          variant="rounded"
          width={'49%'}
          height={'100px'}
          className="mt-4 ms-2"
        />
      </div>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'49%'} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'49%'} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'49%'} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'49%'} className="mb-5" />
      <Skeleton variant="rounded" width={'49%'} height={60} className="mt-5" />
    </Stack>
  );
}

export default VerificationLoader;
