import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Background,
  Handle,
  Position,
  addEdge
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import digitalImage from '../../../assets/images/digital.gif';
import physicalImage from '../../../assets/images/physical.gif';
import { useHistory, useLocation } from 'react-router-dom';

import { createParamsData } from '../../../services/params';
import { MultipleProductDetailsFetch, ProductDetailsFetch } from '../../../services/Auth';
import PhygitalLoader from '../../SkeltonLoaders/PhygitalLoader';
import { decryptText, encryptText, getParamValue } from '../Auth/Login';

const initialEdges = [
  {
    id: '1->2',
    type: 'smoothstep',
    source: '1',
    target: '2',
    sourceHandle: 'a-source',
    targetHandle: 'b-target',
    animated: true,
    style: { stroke: '#555' }
  },
  {
    id: '2->1',
    type: 'smoothstep',
    source: '2',
    target: '1',
    sourceHandle: 'b-source',
    targetHandle: 'a-target',
    animated: true,
    style: { stroke: '#555' }
  }
];

const ImageNode = ({ data }) => (
  <div
    style={{
      width: '180px',
      height: '180px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      position: 'relative'
    }}>
    <Handle
      id="a-source"
      type="source"
      position={Position.Right}
      style={{ top: '30%', background: '#555' }}
    />
    <Handle
      id="a-target"
      type="target"
      position={Position.Right}
      style={{ bottom: '30%', background: '#555' }}
    />
    <Handle
      id="b-source"
      type="source"
      position={Position.Left}
      style={{ bottom: '30%', background: '#555' }}
    />
    <Handle
      id="b-target"
      type="target"
      position={Position.Left}
      style={{ top: '30%', background: '#555' }}
    />
    <div className="d-flex flex-column align-items-center">
      <img
        src={data.image}
        alt="Node"
        style={{ maxWidth: '90%', maxHeight: '90%', width: '140px', borderRadius: '8px' }}
      />
      <span
        className="text-black font-weight-bold font-size-16 position-absolute bottom-0"
        style={{ color: '#161d25' }}>
        {data.label}
      </span>
    </div>
  </div>
);

const nodeTypes = { imageNode: ImageNode };

const Phygital = ({ isWidget }) => {
  const parentURL = document.referrer;
  let parentHostname = null;

  if (parentURL) {
    parentHostname = new URL(parentURL).hostname;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: '1',
      type: 'imageNode',
      position: { x: 60, y: 0 },
      data: { image: digitalImage, label: 'Digital Good' },
      draggable: false
    },
    {
      id: '2',
      type: 'imageNode',
      position: { x: 600, y: 0 },
      data: { image: physicalImage, label: 'Physical Good' },
      draggable: false
    }
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const location = useLocation();
  const history = useHistory();
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  useEffect(() => {
    const interval = setInterval(() => {
      const elements = document.getElementsByClassName('react-flow__panel');
      if (elements.length > 0) {
        elements[0].remove();
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const productDetails = () => {
    setIsLoading(true);
    ProductDetailsFetch(
      getParamValue('product_id', newParams, paramsValue),
      getParamValue('api_key', newParams, paramsValue),
      Number(getParamValue('quantity', newParams, paramsValue) || 1),
      parentHostname,
      encryptText('no_one_time'),
      paramsValue['hash']
    )
      .then((res) => {
        setIsLoading(false);
        if (res.data.data.industryType?.industry_type !== 'physical_goods') {
          if (getParamValue('is_crypto', newParams, paramsValue) === 'yes') {
            history.push({
              pathname: `${isWidget ? '/buy-sell/crypto-payment' : '/crypto-payment'}`,
              search: `${location.search}`
            });
          }
          if (getParamValue('is_exchange', newParams, paramsValue) === 'yes') {
            history.push({
              pathname: `${isWidget ? '/buy-sell/crypto-exchange' : '/crypto-exchange'}`,
              search: `${location.search}`
            });
          } else {
            history.push({
              pathname: `${isWidget ? '/buy-sell/buy-nft' : '/buy-nft'}`,
              search: `${location.search}`
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage(err?.data?.error?.message || 'An error occurred');
      });
  };

  const multipleProductsDetails = () => {
    setIsLoading(true);
    setErrorMessage('');
    let productIds = getParamValue('product_ids', newParams, paramsValue).trim();
    let splitIds = productIds.endsWith(',')
      ? productIds.slice(0, -1).split(',')
      : productIds.split(',');
    splitIds = [...new Set(splitIds)];

    MultipleProductDetailsFetch(
      splitIds,
      getParamValue('api_key', newParams, paramsValue),
      getParamValue('quantities', newParams, paramsValue)
        ? [getParamValue('quantities', newParams, paramsValue)][0].split(',').map(Number)
        : ['1,1'][0].split(',').map(Number),
      parentHostname,
      encryptText('no_one_time'),
      paramsValue['hash']
    )
      .then(() => setIsLoading(false))
      .catch((err) => {
        setErrorMessage(err?.data?.error?.message || 'An error occurred');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      getParamValue('product_ids', newParams, paramsValue) &&
      getParamValue('quantities', newParams, paramsValue)
    ) {
      multipleProductsDetails();
    } else {
      productDetails();
    }
  }, []);

  const nextPage = () => {
    if (getParamValue('is_crypto', newParams, paramsValue) === 'yes') {
      history.push({
        pathname: `${isWidget ? '/buy-sell/crypto-payment' : '/crypto-payment'}`,
        search: `${location.search}`
      });
    } else {
      history.push({
        pathname: `${isWidget ? '/buy-sell/buy-nft' : '/buy-nft'}`,
        search: `${location.search}`
      });
    }
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((els) =>
        addEdge({ ...params, animated: true, style: { stroke: '#555' } }, els)
      ),
    []
  );

  return (
    <div className="p-3" style={{ width: '100%' }}>
      {isLoading ? (
        <PhygitalLoader />
      ) : errorMessage === '' ? (
        <>
          <div style={{ height: '340px', width: '100%' }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              className="easeIn"
              fitView
              attributionPosition="bottom-left"
              panOnDrag={false}
              nodesDraggable={false}
              zoomOnScroll={false}
              zoomOnDoubleClick={false}>
              <Background variant="dots" gap={16} size={1} color="#fff" />
            </ReactFlow>
            <div className="d-flex justify-content-center">
              <span className="font-size-18 text-black" style={{ color: '#a1a1a1' }}>
                Digital Good Linked to Physical Good
              </span>
            </div>
            <div className="d-flex justify-content-center justify-content-center mt-4">
              <button
                id="login-button"
                style={{ width: '200px' }}
                type="button"
                className="btn btn-auth text-capitalize"
                onClick={() => nextPage()}>
                <div className="text-white font-size-12 my-1">Continue</div>
              </button>
            </div>{' '}
          </div>
        </>
      ) : (
        <span
          id="widget-page-error"
          className="p-3 font-size-24 font-weight-bold text-danger">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Phygital;
