export const widgetParams = {
  API_KEY: 'api_key',
  ACCESS_TOKEN: 'accessToken',
  SECRET_KEY: 'secret_key',
  PRODUCT_ID: 'product_id',
  PRODUCT_IDS: 'product_ids',
  PHONE_NUMBER: 'phone',
  EMAIL: 'email',
  FULL_NAME: 'full_name',
  QUANTITY: 'quantity',
  QUANTITIES: 'quantities',
  ADDRESS: 'address',
  MODE: 'mode',
  CUSTOM_ID: 'custom_id',
  LANG: 'lang',
  CLICKID: 'clickID',
  CAMPAIGN_ID: 'campaignID',
  REDIRECT_URL: 'redirect_url',
  FAIL_REDIRECT_URL: 'fail_redirect_url',
  REDIRECT_IMMEDIATE: 'redirect_immediate',
  REDIRECT_PAGE: 'redirect_page',
  UTM_SOURCE: 'utm_source',
  STATE: 'state',
  DATE_OF_BIRTH: 'date_of_birth',
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
  WEBHOOK_WAIT: 'webhook_wait',
  IS_CRYPTO: 'is_crypto',
  IS_EXCHANGE: 'is_exchange',
  HASH: 'hash'
};
// {
//   "first_name": "Satoshi",
//   "last_name": "Nakamoto",
//   "email": "email@gmail.com",
//   "mobileNumber": "+19692154942",
//   "dob": "1994-11-26",
//   "address": {
//       "addressLine1": "170 Pine St",
//       "addressLine2": "San Francisco",
//       "city": "San Francisco",
//       "state": "CA",
//       "postCode": "94111",
//       "countryCode": "US"
//   }
// }
export const createParamsData = (paramsURL) => {
  let paramsValues = {};
  for (const [key, value] of Object.entries(widgetParams)) {
    if (new URLSearchParams(paramsURL).get([value])) {
      paramsValues = {
        ...paramsValues,
        [value]: new URLSearchParams(paramsURL).get([value])
      };
    }
  }

  return paramsValues;
};

export const specialCharactersMap = {
  '%20': ' ',
  '%21': '!',
  '%22': '"',
  '%23': '#',
  '%24': '$',
  '%25': '%',
  '%26': '&',
  '%27': "'",
  '%28': '(',
  '%29': ')',
  '%2A': '*',
  '%2B': '+',
  '%2C': ',',
  '%2D': '-',
  '%2E': '.',
  '%2F': '/',
  '%3A': ':',
  '%3B': ';',
  '%3C': '<',
  '%3D': '=',
  '%3E': '>',
  '%3F': '?',
  '%40': '@',
  '%5B': '[',
  '%5C': '\\',
  '%5D': ']',
  '%5E': '^',
  '%5F': '_',
  '%60': '`',
  '%7B': '{',
  '%7C': '|',
  '%7D': '}',
  '%7E': '~'
};

export const decodeSpecialCharacters = (encodedString) => {
  let decodedString = encodedString;
  for (const [encoded, decoded] of Object.entries(specialCharactersMap)) {
    decodedString = decodedString.replace(new RegExp(encoded, 'g'), decoded);
  }
  return decodedString;
};
