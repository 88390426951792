import api from '../api/userApi';
import * as url from '../api/userURL';
import { encryptText, getDomainName } from '../pages/BuySellWidget/Auth/Login';

export const UserWidgetLoginAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.LOGIN_URL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UserWidgetLoginEmailCheckAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.WIDGET_EMAIL_CHECK, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const VerifyEmailOTPAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VERIFICATION, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ResendVerificationEmailAPI = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.RESEND_VERIFICATION, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetMyProfileAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.MY_PROFILE)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ProfileUpdateAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.WIDGET_UPDATE_PROFILE, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ProductDetailsFetch = (
  product_id,
  api_key,
  quantity,
  parentHostname,
  widget_type,
  hash,
  user_id,
  custom_id
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(
        url.PRODUCT_DETAILS +
          `?product_id=${product_id}&api_key=${encryptText(
            api_key
          )}&quantity=${quantity}&parentHostname=${parentHostname}&widget_type=${widget_type}&hash=${hash}&user_id=${user_id}&custom_id=${custom_id}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ProductExchangeDetailsFetch = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.PRODUCT_EXCHANGE_DETAILS, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const MultipleProductDetailsFetch = (
  product_ids,
  api_key,
  quantities,
  parentHostname,
  widget_type,
  hash,
  user_id,
  custom_id
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.MULTIPLE_PRODUCT_DETAILS, {
        product_ids,
        api_key: encryptText(api_key),
        quantities,
        parentHostname,
        widget_type,
        hash,
        user_id,
        custom_id
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetDomainDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.DOMAIN, { ...data, domain: getDomainName(window.location.hostname) })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetOrderDetailsAPI = (order_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.ORDER_DETAILS + `?order_id=${order_id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const TriggerWertWebhook = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.WERT_WEBHOOK, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
