import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function PhygitalLoader() {
  return (
    <Stack spacing={1} className="p-3 mt-5">
      <div className="d-flex justify-content-between mt-5 mb-5">
        <Skeleton
          variant="rounded"
          width={'20%'}
          height={'100px'}
          className="mt-4 me-2"
        />
        <div className="d-flex flex-column mt-5" style={{ width: '40%' }}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" className="mt-2" sx={{ fontSize: '1rem' }} />
        </div>
        <Skeleton
          variant="rounded"
          width={'20%'}
          height={'100px'}
          className="mt-4 ms-2"
        />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'49%'} height={50} />
      </div>
      <div className="d-flex justify-content-center">
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} height={80} />
      </div>
    </Stack>
  );
}

export default PhygitalLoader;
